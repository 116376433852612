import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithReauth } from "../Auth/ConfigApi";

export const EventApi = createApi({
  reducerPath: "EventApi",
  baseQuery: baseQueryWithReauth,

  tagTypes: ["EventApi"],

  endpoints: (builder) => ({
    eventCard: builder.query({
      query: (params) => ({
        url: `home/events`,
        params,
      }),
      providesTags: ["EventApi"],
    }),
  }),
});

export const { useEventCardQuery } = EventApi;
