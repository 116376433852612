import React, { createContext, useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "js-cookie";
import { logOut, setCredentials } from "../store/api/Auth/AuthSlice";
import { useLogOutMutation } from "../store/api/Login/AuthApi";
import Loading from "../Loader/Loading";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const authState = useSelector((state) => state.auth);
  const [LogOut, { isLoading, isSuccess: isSuccessLogout }] =
    useLogOutMutation();

  useEffect(() => {
    const token = Cookies.get("access_token");
    const refresh_token = Cookies.get("refresh_token");
    const user = Cookies.get("user");
    if (token && !authState?.access_token) {
      dispatch(
        setCredentials({
          user: JSON?.parse(user) || {},
          access_token: token,
          refresh_token: refresh_token,
        })
      );
    }
  }, [dispatch, authState.access_token]);

  const signOut = async () => {
    setLoading(true);
    await LogOut();
    dispatch(logOut());
    Cookies.remove("user");
    Cookies.remove("access_token");
    Cookies.remove("refresh_token");
    setLoading(false);
    localStorage.removeItem("hcpAvatar"); 
  };
  
  if(isLoading || loading) return <Loading  isLoading ={true} />

  return (
    <AuthContext.Provider
      value={{
        authState,
        user: authState?.user,
        signOut,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
