import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Indicator } from "@mantine/core";
import NotificationMenu from "../Chat/Notifications/NotificationMenu";
import UserMenu from "./UserMenu";
import { useAuth } from "../Context/authContext";
import { TfiMedall } from "react-icons/tfi";

export default function AfterLogin() {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [points, setPoints] = useState(user ? user.points : "0");

  useEffect(() => {
    if (user) {
      setPoints(user.points);
    } else {
      setPoints("0");
    }
  }, [user.points]);

  return (
    <div className="hidden lg:flex items-center gap-4">
      {/* Scoring Points */}
      <Indicator
        inline
        label={points}
        size={16}
        color="#012e54"
        position="top-end"
        className="cursor-pointer hover:bg-bgmain hover:rounded-full hover:w-auto p-2"
      >
        <TfiMedall className="text-main text-xl" />
        {/* <img src={scoringPoints} alt="" /> */}
      </Indicator>

      {/* Notification */}
      <NotificationMenu/>

      {/* Messages */}
      <Indicator
        inline
        color="#012e54"
        size={14}
        label="0"
        position="top-end"
        className="cursor-pointer hover:bg-bgmain hover:rounded-full hover:w-auto p-1"
        onClick={() => navigate("/coming-soon")}
      >
        <i className="bi bi-chat-dots-fill text-main text-xl"></i>
      </Indicator>

      {/* User */}
      <UserMenu />
    </div>
  );
}
