import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithReauth } from "../Auth/ConfigApi";

export const sharedAPI = createApi({
  reducerPath: "FollowStatus",
  baseQuery: baseQueryWithReauth,
  tagTypes: ["FollowStatus"],

  endpoints: (builder) => ({
    followProfile: builder.mutation({
      query: (params) => ({
        method: "POST",
        url: `/follow/${params}`,
      }),
      invalidatesTags: ["FollowStatus"],
    }),
  }),
});

export const { useFollowProfileMutation } = sharedAPI;
