import React, { useState, useEffect } from "react";
// Import Images
import Live from "../assets/images/live.png";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useAuth } from "../Context/authContext";
import GuestUserModal from "../Shared/Modals/GuestUserModal";
import Cookies from "js-cookie";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { CloseLiveBar, setZoomMeetingData } from "../store/slices/zoomSlice";
import { useIpAddressQuery } from "../store/api/withoutBASE_URL/ZoomMettings";

export default function LiveBar() {
  const [show, setShow] = useState(true);
  const [guestModalOpen, setGuestModalOpen] = useState(false);
  const { user } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { LiveBarState, zoomMettingData } = useSelector(
    (state) => state.zoomSlice
  );
  const authState = useSelector((state) => state.auth);

  const { data: meetingZoomData, isLoading } = useIpAddressQuery({
    email: authState?.user?.email,
  });

  useEffect(() => {
    if (location?.pathname === "/live-event") {
      setShow(false);
    } else {
      setShow(true);
    }
  }, [location.pathname]);

  useEffect(() => {
    if (!zoomMettingData?.code) {
      dispatch(setZoomMeetingData(meetingZoomData));
    }
  }, [meetingZoomData]);

  const handleClick = () => {
    if (user) {
      navigate("/live-event");
    } else {
      setGuestModalOpen(true);
    }
  };

  const handleClose = () => {
    setShow(false);
    return dispatch(CloseLiveBar(false));
  };
  return (
    <>
      {LiveBarState && show && (
        <div className="livebar">
          <div className="d-flex justify-content-center align-items-center gap-3">
            <img src={Live} alt="" />
            {isLoading ? (
              <Link
                to="#"
              >
                Loading...
              </Link>
            ) : (
              <Link
                to="#"
                onClick={(e) => {
                  e.preventDefault();
                  handleClick();
                }}
              >
                {zoomMettingData?.data?.title || "coming soon"}
              </Link>
            )}

            <GuestUserModal
              opened={guestModalOpen}
              onClose={() => setGuestModalOpen(false)}
            />
          </div>
          <i className="bi bi-x-circle" onClick={handleClose}></i>
        </div>
      )}
    </>
  );
}
