import { fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import Cookies from "js-cookie";
import { setCredentials, logOut } from "./AuthSlice";
import { BASE_URL } from "../../../units/apiConfig";

export const baseQuery = fetchBaseQuery({
  baseUrl: BASE_URL,
  prepareHeaders: (headers) => {
    const token = Cookies.get("access_token");
    if (token) {
      headers.set("Authorization", `Bearer ${token}`);
      headers.set("Accept", "application/json");
    }
    return headers;
  },
});

export const baseQueryWithReauth = async (args, api, extraOptions) => {
  const headers = new Headers();
  if (!extraOptions?.multipart) {
    headers.set("Content-Type", "application/json");
  }
  let result = await baseQuery(args, api, extraOptions);

  if (result.error && result.error.status === 401) {
    const refreshToken = Cookies.get("refresh_token");

    let refreshResult;
    if (refreshToken) {
      refreshResult = await baseQuery(
        {
          url: "/auth/refresh_token",
          method: "POST",
          body: { refresh_token: refreshToken },
        },
        api,
        extraOptions
      );
    }

    if (refreshResult?.data) {
      const user = api.getState().auth.user;
      api.dispatch(
        setCredentials({
          user,
          access_token: refreshResult?.data?.access_token,
          refresh_token: refreshResult?.data?.refresh_token,
        })
      );
      Cookies.set("user", JSON.stringify(refreshResult?.data?.user), {
        expires: 1,
      });
      Cookies.set("access_token", refreshResult?.data?.access_token, {
        expires: 1,
      });
      Cookies.set("refresh_token", refreshResult?.data?.refresh_token);
      result = await baseQuery(args, api, extraOptions);
    } else {
      api.dispatch(logOut());
      Cookies.remove("user");
      Cookies.remove("access_token");
      Cookies.remove("refresh_token");
    }
  }
  return result;
};
