import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithReauth } from "../Auth/ConfigApi";
import Cookies from "js-cookie";
export const Search = createApi({
  reducerPath: "Search",
  baseQuery: baseQueryWithReauth,
  tagTypes: ["Search"],

  endpoints: (builder) => ({
    getSearch: builder.query({
      query: (params) => ({
        method: "GET",
        url: `/search`,
        params,
      }),
      providesTags: ["Search"],
    }),
    getSearchHistory: builder.query({
      query: (params) => ({
        method: "GET",
        url: `/search/history`,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${Cookies.get("access_token")}`,
        },
        params,
      }),
      providesTags: ["Search"],
    }),
  }),
});

export const { useGetSearchQuery, useGetSearchHistoryQuery } = Search;
