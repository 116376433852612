import React from "react";
import { useNavigate } from "react-router-dom";
import { Avatar, Indicator } from "@mantine/core";
import { useAuth } from "../Context/authContext";
import { TfiMedall } from "react-icons/tfi";
import { useGetNotificationsListQuery } from "../store/api/Home/NotificationApi";
import avatar from "../assets/images/avatar.png";
import { generateSlug } from "../units/GenerateSlug";
export default function SideMenuIcons() {
  const navigate = useNavigate();
  const { user } = useAuth();
  const userAvatar = localStorage.getItem("hcpAvatar");
  const userNavigate = {
    HCP: "hcp-profile",
    Center: "hcc-profile",
    Hospital: "mc-profile",
  };
  const { data: NotificationData } = useGetNotificationsListQuery();
  return (
    <>
      <div className="flex items-center justify-center w-100 gap-4 pt-3">
        {/* Scoring Points */}
        <Indicator
          inline
          label={user ? user.points : "0"}
          size={18}
          color="#012e54"
          position="top-end"
          className=" cursor-pointer hover:bg-bgmain hover:rounded-full hover:w-auto p-2"
        >
          <TfiMedall className="text-main text-xl" />
          {/* <img src={scoringPoints} alt="" /> */}
        </Indicator>

        {/* Notification */}
        <Indicator
          inline
          color="#012e54"
          size={12}
          label={NotificationData ? `${NotificationData?.data?.length}` : "0"}
          position="top-end"
          className="cursor-pointer hover:bg-bgmain hover:rounded-full  hover:w-auto p-1"
        >
          <i
            className="bi bi-bell-fill text-main text-xl"
            onClick={() => navigate("/notifications")}
          ></i>
        </Indicator>

        {/* Messages */}
        <Indicator
          inline
          color="#012e54"
          size={12}
          label="0"
          position="top-end"
          className="cursor-pointer hover:bg-bgmain hover:rounded-full  hover:w-auto p-1"
          onClick={() => navigate("/coming-soon")}
        >
          <i className="bi bi-chat-dots-fill text-main text-xl"></i>
        </Indicator>

        {/* User */}
        <Avatar
          src={userAvatar || avatar}
          alt="user-profile-image"
          className="border-2 cursor-pointer"
          style={{ borderColor: "var(--basic-color)" }}
          onClick={() =>
            navigate(
              `/${userNavigate[user?.user_type]}/${generateSlug(
                user?.name
              )}?id=${user?.id}`,
              {
                state: { userID: user?.id },
              }
            )
          }
        />
      </div>
    </>
  );
}
