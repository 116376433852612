import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithReauth } from "../Auth/ConfigApi";

export const Newsletter = createApi({
  reducerPath: "Newsletter",
  baseQuery: baseQueryWithReauth,
  tagTypes: ["Newsletter"],

  endpoints: (builder) => ({
    addNewsLetter: builder.mutation({
      query: (params) => ({
        method: "POST",
        url: `/newsletter/subscribe`,
        body: params,
      }),
      providesTags: ["Newsletter"],
    }),
  }),
});

export const { useAddNewsLetterMutation } = Newsletter;
