import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const IPv4API = createApi({
  reducerPath: "LoginApi",
  baseQuery: fetchBaseQuery({
    baseUrl: ``,
  }),
  tagTypes: ["IPv4API"],

  endpoints: (builder) => ({
    ipAddress: builder.query({
      query: () => ({
        url: `https://ipapi.co/json/`,
      }),
      providesTags: ["IPv4API"],
    }),
  }),
});

export const { useIpAddressQuery } = IPv4API;
