import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  LiveBarState: true,
  zoomMettingData : {},
};

const zoomMeetingSlice = createSlice({
  name: "zoomMeeting",
  initialState,
  reducers: {
    CloseLiveBar: (state, action) => {
      state.LiveBarState = action.payload;
    },
    setZoomMeetingData: (state, action) => {
      state.zoomMettingData = action.payload;
    },
  },
});

// Export actions and reducer for zoomMeetingSlice
export const { CloseLiveBar , setZoomMeetingData } = zoomMeetingSlice.actions;
export const zoomMeetingReducer = zoomMeetingSlice.reducer;
