import { configureStore } from "@reduxjs/toolkit";
import queryMiddlewares from "./api/queryMiddlewares";
import rootReducer from "./api/rootReducer";
import { ApiMiddleware } from "./middlewares/Api.middleware";

const middlewares = [...queryMiddlewares];
middlewares.push(ApiMiddleware);

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    }).concat(middlewares),
});

export default store;
