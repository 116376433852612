import {
  Accordion,
  ActionIcon,
  FileInput,
  Image,
  Progress,
  Select,
  Text,
  TextInput,
} from "@mantine/core";
import { HiOutlinePhoto } from "react-icons/hi2";
import { DatePickerInput } from "@mantine/dates";
import React, { useEffect, useState } from "react";
import { PiCurrencyDollar } from "react-icons/pi";
import { IoIosAddCircleOutline, IoMdCloseCircleOutline } from "react-icons/io";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../../../Context/authContext";
import {
  useCreateEducationMutation,
  useSingleEducationQuery,
  useUpdateEducationLessonMutation,
  useUpdateEducationModuleMutation,
  useUpdateEducationMutation,
  useUploadFilesMutation,
} from "../../../store/api/Home/EducationsApi";
import { BiSolidEdit, BiTrash } from "react-icons/bi";
import { generateSlug } from "../../../units/GenerateSlug";

const unsafeInputRegex =
  /(<script\b[^>]*>.*?<\/script>|<\/?\w+[^>]*>|style\s*=\s*["'][^"']*["']|javascript:|data:text\/html|select\s+\*\s+from\s+\w+|insert\s+into\s+\w+\s+\(.*\)\s+values\s+\(.*\)|update\s+\w+\s+set\s+.*=.*|delete\s+from\s+\w+|drop\s+table\s+\w+|union\s+select\s+.*|;\s*|--|\/\*.*?\*\/|#|\$)/i;

export default function CourseForm() {
  const { user } = useAuth();

  const location = useLocation();
  const id = location?.state?.courseID;
  const chapterID = location?.state?.moduleID;
  const lessonID = location?.state?.lessonID;

  const isDisabled = id && !chapterID;
  const navigate = useNavigate();

  const [previewImage, setPreviewImage] = useState(null);
  const [imageUrl, setImageUrl] = useState("");
  const [imageError, setImageError] = useState("");
  const [uploadStatus, setUploadStatus] = useState(null);
  const [uploadFileStatus, setUploadFileStatus] = useState({});
  const [isUploading, setIsUploading] = useState(false);

  const moduleIndex = localStorage.getItem("module_index");
  const lessonIndex = localStorage.getItem("lesson_index");
  const active_module_index = localStorage.getItem("active_module_index");

  const validateInput = (value) => {
    const containsString = /[a-zA-Z]/.test(value);
    const containsNumber = /[0-9]/.test(value);
    const stringValue = value?.trim();

    if (!containsString || (!containsString && !containsNumber)) {
      return "Input cannot be only numerical";
    }

    if (stringValue?.length < 10 || stringValue?.length > 250) {
      return "Input length must be between 10 and 250 characters";
    }

    if (unsafeInputRegex?.test(value)) {
      return "Invalid input: Potentially harmful content detected.";
    }

    return null;
  };

  const {
    control,
    register,
    handleSubmit,
    watch,
    formState: { errors, isValid },
    setValue,
    reset,
    setError,
    clearErrors,
  } = useForm({
    mode: "onChange",
  });

  const startDate = watch("start_date");
  const startAt = watch("start_at");
  const selectedType = watch("type");

  const [modules, setModules] = useState([{ id: "module-1", lessons: [{}] }]);

  const handleAddLesson = (moduleIndex) => {
    setModules((prevModules) => {
      // Check if the module at the given index exists
      if (!prevModules[moduleIndex]) {
        console.error(`Module at index ${moduleIndex} is undefined.`);
        return prevModules; // Return the previous state to avoid breaking
      }

      // Safely update the lessons array
      const updatedModules = [...prevModules];
      if (!updatedModules[moduleIndex].lessons) {
        // If lessons is undefined, initialize it as an array
        updatedModules[moduleIndex].lessons = [{}];
      } else {
        updatedModules[moduleIndex].lessons.push({});
      }

      return updatedModules;
    });
  };

  const handleAddSection = () => {
    const newModuleId = `module-${modules.length + 1}`;
    setModules((prevModules) => [
      ...prevModules,
      { id: newModuleId, lessons: [{}] }, // Ensure new modules have a lessons array
    ]);
  };

  const [AddCourse] = useCreateEducationMutation();
  const [UpdateEducation] = useUpdateEducationMutation();
  const [UpdateModule] = useUpdateEducationModuleMutation();
  const [UpdateLesson] = useUpdateEducationLessonMutation();
  const [UploadFiles] = useUploadFilesMutation();
  const { data: singleEducation } = useSingleEducationQuery(id, { skip: !id });

  const handleFileUpload = async (
    file,
    moduleIndex,
    lessonIndex,
    typeId,
    fieldName
  ) => {
    if (!file || !typeId) return;

    const formData = new FormData();
    formData.append("file", file);

    if (typeId === "1") {
      formData.append("type", "video");
    } else if (typeId === "2") {
      formData.append("type", "file");
    } else {
      formData.append("type", "image");
    }

    setIsUploading(true);
    try {
      if (typeId === "1" || typeId === "2") {
        setUploadFileStatus((prevStatus) => ({
          ...prevStatus,
          [`module-${moduleIndex}-lesson-${lessonIndex}`]: "Uploading...",
        }));
      } else {
        setUploadStatus("Uploading...");
      }
      const response = await UploadFiles(formData).unwrap();

      if (fieldName === "image") {
        setValue(fieldName, response.data.file_path);
      } else {
        setValue(fieldName, response.data.file_path);
      }

      if (typeId === "1" || typeId === "2") {
        setUploadFileStatus((prevStatus) => ({
          ...prevStatus,
          [`module-${moduleIndex}-lesson-${lessonIndex}`]: "Upload successful",
        }));
      } else {
        setUploadStatus("Upload successful");
      }
    } catch (error) {
      console.error("File upload failed:", error);
      if (typeId === "1" || typeId === "2") {
        setUploadFileStatus((prevStatus) => ({
          ...prevStatus,
          [`module-${moduleIndex}-lesson-${lessonIndex}`]:
            "Upload failed. Please try again.",
        }));
      } else {
        setUploadStatus("Upload failed. Please try again.");
      }
    } finally {
      setIsUploading(false);
    }
  };

  const handleFileChange = (file, moduleIndex, lessonIndex) => {
    const typeId = watch(`module-${moduleIndex}-lesson-${lessonIndex}-type_id`);
    if (!typeId) {
      setError(`module-${moduleIndex}-lesson-${lessonIndex}-file`, {
        type: "manual",
        message: "Please select the attachment type first.",
      });
      return;
    }

    const fieldName = `module-${moduleIndex}-lesson-${lessonIndex}-attachment_url`;

    handleFileUpload(file, moduleIndex, lessonIndex, typeId, fieldName);
  };

  const validateImage = (file) => {
    const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5MB in bytes
    const ALLOWED_TYPES = ["image/jpeg", "image/png", "image/gif"]; // Allowed image types

    // Check the file type
    if (!ALLOWED_TYPES.includes(file.type)) {
      setImageError(
        "Invalid file type. Please upload a JPEG, PNG, or GIF image."
      );
      return false;
    }

    // Check the file size
    if (file.size > MAX_FILE_SIZE) {
      setImageError(`File size exceeds 5MB. Please upload a smaller image.`);
      return false;
    }

    // Clear error if file is valid
    setImageError("");
    return true;
  };

  const onSubmit = async (data) => {
    console.log(data);

    try {
      if (data?.start_date) {
        const startDate = new Date(data?.start_date);
        data.start_date = startDate?.toLocaleDateString("en-CA");
      }

      if (data?.end_date) {
        const endDate = new Date(data?.end_date);
        data.end_date = endDate?.toLocaleDateString("en-CA");
      }

      const transformedModules = [];
      const transformedLessons = [];

      const additionalData = {
        speciality_id: user?.speciality?.[0]?.id,
        instructor_name: user?.name,
        education_id: id,
      };

      modules.forEach((module, moduleIndex) => {
        // Push each module data to transformedModules
        transformedModules.push({
          name: data[`module-${moduleIndex}-name`],
          description: data[`module-${moduleIndex}-description`],
        });

        // Collect lessons for the current module
        module.lessons.forEach((_, lessonIndex) => {
          transformedLessons.push({
            name: data[`module-${moduleIndex}-lesson-${lessonIndex}-name`],
            lesson_details:
              data[
                `module-${moduleIndex}-lesson-${lessonIndex}-lesson_details`
              ],
            module_index: moduleIndex, // Link the lesson to the module
            type_id:
              data[`module-${moduleIndex}-lesson-${lessonIndex}-type_id`],
            lesson_duration: 10,
            //   data[
            //     `module-${moduleIndex}-lesson-${lessonIndex}-lesson_duration`
            //   ],
            attachment_url:
              data[
                `module-${moduleIndex}-lesson-${lessonIndex}-attachment_url`
              ],
          });
        });
      });

      // const moduleData = {
      //   name: data[`module-${moduleIndex}-name`],
      //   description: data[`module-${moduleIndex}-description`],
      // };

      // const lessonData = {
      //   name: data[`module-${moduleIndex}-lesson-${lessonIndex}-name`],
      //   lesson_details:
      //     data[`module-${moduleIndex}-lesson-${lessonIndex}-lesson_details`],
      //   module_index: moduleIndex, // Link the lesson to the module
      //   type_id: data[`module-${moduleIndex}-lesson-${lessonIndex}-type_id`],
      //   lesson_duration: 10,
      //   //   data[
      //   //     `module-${moduleIndex}-lesson-${lessonIndex}-lesson_duration`
      //   //   ],
      //   attachment_url:
      //     data[`module-${moduleIndex}-lesson-${lessonIndex}-attachment_url`],
      // };

      const courseData = {
        name: data?.name,
        description: data?.description,
        start_date: data?.start_date,
        end_date: data?.end_date,
        type: data?.type || "free",
        price: data?.price || "",
        image: data?.image,
      };

      const educationeData = {
        ...additionalData,
        ...courseData,
        modules: transformedModules,
        lessons: transformedLessons,
      };

      const updatedCourseData = { ...data, ...additionalData };

      if (id && !chapterID && !lessonID) {
        const result = await UpdateEducation(updatedCourseData).unwrap();
        reset();
        if (result?.id) {
          navigate(`/course/${generateSlug(result?.name)}?id=${result?.id}`, {
            state: { courseID: result?.id },
          });
        }
      } else if (chapterID && !lessonID) {
        await UpdateModule({
          education_id: id,
          module_id: chapterID,
          ...transformedModules[0],
        }).unwrap();
        if (singleEducation?.id) {
          navigate(
            `/course/${generateSlug(singleEducation?.name)}?id=${
              singleEducation?.id
            }`,
            {
              state: { courseID: singleEducation?.id },
            }
          );
        }
      } else if (lessonID && !chapterID) {
        console.log("Calling UpdateLesson");
        await UpdateLesson({
          ...transformedLessons[0],
          education_id: id,
          lesson_id: lessonID,
        }).unwrap();
        if (singleEducation?.id) {
          navigate(
            `/course/${generateSlug(singleEducation?.name)}?id=${
              singleEducation?.id
            }`,
            {
              state: { courseID: singleEducation?.id },
            }
          );
        }
      } else {
        const result = await AddCourse(educationeData).unwrap();
        reset();
        if (result?.education?.id) {
          navigate(
            `/course/${generateSlug(result?.education?.name)}?id=${
              result?.education?.id
            }`,
            {
              state: { courseID: result?.education?.id },
            }
          );
        }
      }
      reset();
    } catch (error) {
      console.error("Failed to add education:", error);
    }
  };

  useEffect(() => {
    if (id && !chapterID && !lessonID) {
      reset({
        name: singleEducation?.name || "",
        description: singleEducation?.description || "",
        type: singleEducation?.type || null,
        price: singleEducation?.price || "",
        start_at: singleEducation?.start_at
          ? new Date(singleEducation.start_at)
          : null,
        end_date: singleEducation?.end_date
          ? new Date(singleEducation.end_date)
          : null,
      });

      if (singleEducation?.image && singleEducation.image instanceof File) {
        setPreviewImage(URL.createObjectURL(singleEducation?.image));
      } else if (typeof singleEducation?.image === "string") {
        setPreviewImage(singleEducation?.image);
      }
    } else if (chapterID) {
      if (
        singleEducation &&
        singleEducation.Modules &&
        singleEducation.Modules.data &&
        singleEducation.Modules.data.length
      ) {
        const module = singleEducation?.Modules?.data[moduleIndex];
        setValue(`module-0-name`, module.name || "");
        setValue(`module-0-description`, module.description || "");
      }
    } else if (lessonID) {
      if (
        singleEducation?.Modules?.data[active_module_index]?.lessons?.data
          ?.length
      ) {
        const lesson =
          singleEducation?.Modules?.data[active_module_index]?.lessons?.data[
            lessonIndex
          ];

        setValue(`module-0-lesson-0-name`, lesson.name || "");
        setValue(
          `module-0-lesson-0-lesson_details`,
          lesson.lesson_details || ""
        );
        setValue(`module-0-lesson-0-type_id`, lesson.type_id || "");
        setValue(`module-0-lesson-0-file`, lesson.attachment_url || "");
      }
    }
  }, [
    reset,
    singleEducation,
    chapterID,
    id,
    moduleIndex,
    lessonID,
    lessonIndex,
    active_module_index,
    setValue,
  ]);

  return (
    <>
      <section className="py-28 px-20 w-full max-lg:px-2 sm:max-lg:px-20">
        <div className="w-full">
          <h4 className="text-hoverColor text-2xl font-bold font-title mb-2">
            {id && singleEducation ? "Update Course" : " New Course"}
          </h4>
          <form
            className="mt-4"
            onSubmit={handleSubmit(onSubmit)}
            encType="multipart/form-data"
          >
            <div className="flex flex-col-reverse justify-between lg:flex-row lg:items-start lg:gap-8 w-full">
              <div className="flex flex-col w-full lg:w-2/3">
                {/* Education Details */}
                <div className="flex flex-col w-full">
                  {/* Title */}
                  <div>
                    <Controller
                      name="name"
                      control={control}
                      rules={{
                        required: {
                          value: chapterID || lessonID ? false : true,
                          message: "Please insert course title",
                        },
                        validate: (value) => {
                          const validationError = validateInput(value);
                          if (validationError) {
                            return validationError;
                          }
                          return true;
                        },
                      }}
                      render={({ field }) => (
                        <TextInput
                          {...field}
                          label="Title"
                          placeholder="Add Course Title"
                          className="w-full"
                          classNames={{ input: "py-4 !rounded-lg w-full" }}
                          disabled={chapterID || lessonID}
                          onChange={(e) => {
                            field.onChange(e);
                          }}
                          error={errors?.name?.message}
                        />
                      )}
                    />
                  </div>

                  {/* Description */}
                  <div>
                    <Controller
                      name="description"
                      control={control}
                      rules={{
                        required: {
                          value: chapterID || lessonID ? false : true,
                          message: "Please insert course description.",
                        },
                        validate: (value) => {
                          const validationError = validateInput(value);
                          if (validationError) {
                            return validationError;
                          }
                          return true;
                        },
                      }}
                      render={({ field }) => (
                        <TextInput
                          {...field}
                          mt="xl"
                          label="Description"
                          placeholder="Add Course Description Here.."
                          className="w-full "
                          classNames={{ input: "py-4 !rounded-lg w-full" }}
                          disabled={chapterID || lessonID}
                          onChange={(e) => {
                            field.onChange(e);
                          }}
                          error={errors?.description?.message}
                        />
                      )}
                    />
                  </div>
                  {/* Date */}
                  <div className="flex flex-col justify-between items-center md:flex-row gap-4">
                    <div className="flex flex-col justify-between items-end sm:flex-row lg:flex-col xl:flex-row gap-4 w-full">
                      <Controller
                        name={id && singleEducation ? "start_at" : "start_date"}
                        control={control}
                        rules={{
                          required: {
                            value: chapterID || lessonID ? false : true,
                            message: `Please insert end date`,
                          },
                        }}
                        render={({ field }) => (
                          <DatePickerInput
                            {...field}
                            mt="xl"
                            name={
                              id && singleEducation ? "start_at" : "start_date"
                            }
                            label="Starting Date "
                            placeholder="Start Date"
                            disabled={chapterID || lessonID}
                            className="w-full"
                            clearable
                            classNames={{ input: "!py-4 !rounded-lg !w-full" }}
                            error={
                              errors?.start_date?.message ||
                              errors?.start_at?.message
                            }
                          />
                        )}
                      />
                    </div>

                    <div className="flex flex-col justify-between items-end sm:flex-row lg:flex-col xl:flex-row  gap-4 w-full">
                      <Controller
                        name="end_date"
                        control={control}
                        render={({ field }) => (
                          <DatePickerInput
                            {...field}
                            mt="xl"
                            name="end_date"
                            label="Ending Date "
                            placeholder="End Date"
                            className="w-full"
                            disabled={chapterID || lessonID}
                            clearable
                            minDate={startDate || startAt || null}
                            classNames={{ input: "!py-4 !rounded-lg !w-full" }}
                            error={errors?.end_date?.message}
                          />
                        )}
                        rules={{
                          validate: {
                            notEqualToStartDate: (value) =>
                              !value ||
                              !startDate ||
                              !startAt ||
                              value >= startDate ||
                              startAt ||
                              "End date cannot be earlier than start date.",
                            notSameAsStartDate: (value) =>
                              !value ||
                              !startDate ||
                              !startAt ||
                              value.toLocaleDateString("en-CA") !==
                                startDate.toLocaleDateString("en-CA") ||
                              startAt.toLocaleDateString("en-CA") ||
                              "End date cannot be the same as start date.",
                          },
                        }}
                      />
                    </div>
                  </div>

                  {/* Category and price */}
                  {user && user?.is_premium === 1 && (
                    <div className=" flex flex-col justify-between items-center md:flex-row gap-4">
                      <div className="flex flex-col justify-between items-end sm:flex-row lg:flex-col xl:flex-row gap-4 w-full">
                        <Controller
                          name="type"
                          control={control}
                          rules={{
                            required: {
                              value: chapterID || lessonID ? false : true,
                              message: `Please insert course category`,
                            },
                          }}
                          render={({ field }) => (
                            <Select
                              {...field}
                              mt="xl"
                              name="type"
                              label="Category"
                              placeholder="Add Course Category.."
                              data={[
                                { value: "free", label: "Free" },
                                { value: "paid", label: "Paid" },
                                { value: "premium", label: "Premium" },
                              ]}
                              disabled={chapterID || lessonID}
                              clearable
                              searchable
                              rightSectionWidth={30}
                              className="w-full"
                              classNames={{
                                input: "!py-6 !rounded-lg !w-full",
                              }}
                              error={errors?.type?.message}
                            />
                          )}
                        />
                      </div>

                      {selectedType === "paid" && (
                        <div className="flex flex-col justify-between items-end sm:flex-row lg:flex-col xl:flex-row gap-4 w-full">
                          <Controller
                            name="price"
                            control={control}
                            rules={{
                              required: {
                                value: chapterID || lessonID ? false : true,
                                message: "Please insert course price.",
                              },
                              pattern: {
                                value: /^\d*$/,
                                message: "Only numeric values are allowed",
                              },
                            }}
                            render={({ field }) => (
                              <TextInput
                                {...field}
                                mt="xl"
                                label="Course Price"
                                placeholder="Add Course price"
                                disabled={chapterID || lessonID}
                                className="w-full"
                                classNames={{
                                  input: "py-4 !rounded-lg !w-full",
                                }}
                                leftSection={<PiCurrencyDollar size={16} />}
                                error={errors?.price?.message}
                              />
                            )}
                          />
                        </div>
                      )}
                    </div>
                  )}
                </div>

                {/* Chapter & Lessons */}
                <Accordion
                  variant="separated"
                  defaultValue="module-1"
                  className="mt-4"
                >
                  {/* Module Accordion */}
                  {modules.map((module, moduleIndex) => (
                    <Accordion.Item key={module.id} value={module.id}>
                      <Accordion.Control>
                        <div className=" w-full">
                          <h5 className="text-hoverColor text-base font-bold font-title mb-0">
                            {" "}
                            New Module
                          </h5>
                        </div>
                      </Accordion.Control>

                      <Accordion.Panel>
                        <Controller
                          name={`module-${moduleIndex}-name`}
                          control={control}
                          rules={{
                            required: {
                              value: id && singleEducation ? false : true,
                              message: "Please insert module title",
                            },
                            validate: (value) => {
                              const validationError = validateInput(value);
                              if (validationError) {
                                return validationError;
                              }
                              return true;
                            },
                          }}
                          render={({ field }) => (
                            <TextInput
                              {...field}
                              mt="lg"
                              label="Module Title"
                              placeholder="Add Module Title"
                              className="w-full"
                              disabled={isDisabled}
                              classNames={{
                                input: "py-4 !rounded-lg w-full",
                              }}
                              error={
                                errors?.[`module-${moduleIndex}-name`]?.message
                              }
                            />
                          )}
                        />

                        <Controller
                          name={`module-${moduleIndex}-description`}
                          control={control}
                          rules={{
                            required: {
                              value: id && singleEducation ? false : true,
                              message: "Please insert module description",
                            },
                            validate: (value) => {
                              const validationError = validateInput(value);
                              if (validationError) {
                                return validationError;
                              }
                              return true;
                            },
                          }}
                          render={({ field }) => (
                            <TextInput
                              {...field}
                              mt="xl"
                              label="Module Description"
                              placeholder="Add Module Description Here.."
                              className="w-full"
                              disabled={isDisabled}
                              classNames={{
                                input: "py-4 !rounded-lg w-full",
                              }}
                              error={
                                errors?.[`module-${moduleIndex}-description`]
                                  ?.message
                              }
                            />
                          )}
                        />
                      </Accordion.Panel>

                      {module.lessons.map((_, lessonIndex) => (
                        <Accordion.Panel key={`lesson-${lessonIndex}`}>
                          <div className="lesson ms-8 ps-8 border-l-2 border-[#f5f5f5]">
                            <div className="w-full mb-4 mt-4 flex justify-between items-center ">
                              <h5 className="text-hoverColor text-base font-bold font-title mb-0">
                                New Lesson
                              </h5>
                            </div>
                            <div>
                              <Controller
                                name={`module-${moduleIndex}-lesson-${lessonIndex}-name`}
                                control={control}
                                rules={{
                                  required: {
                                    value: id && singleEducation ? false : true,
                                    message: "Please insert your lesson title",
                                  },
                                  validate: (value) => {
                                    const validationError =
                                      validateInput(value);
                                    if (validationError) {
                                      return validationError;
                                    }
                                    return true;
                                  },
                                }}
                                render={({ field }) => (
                                  <TextInput
                                    {...field}
                                    label="Lesson Title"
                                    placeholder="Add Lesson Title"
                                    className="w-full"
                                    disabled={!lessonID && id}
                                    classNames={{
                                      input: "py-4 !rounded-lg w-full",
                                    }}
                                    error={
                                      errors?.[
                                        `module-${moduleIndex}-lesson-${lessonIndex}-name`
                                      ]?.message
                                    }
                                  />
                                )}
                              />

                              <Controller
                                name={`module-${moduleIndex}-lesson-${lessonIndex}-lesson_details`}
                                control={control}
                                rules={{
                                  required: {
                                    value: id && singleEducation ? false : true,
                                    message:
                                      "Please insert your lesson description",
                                  },
                                  validate: (value) => {
                                    const validationError =
                                      validateInput(value);
                                    if (validationError) {
                                      return validationError;
                                    }
                                    return true;
                                  },
                                }}
                                render={({ field }) => (
                                  <TextInput
                                    {...field}
                                    mt="xl"
                                    label="Lesson Description"
                                    placeholder="Add Lesson Description"
                                    className="w-full "
                                    disabled={!lessonID && id}
                                    classNames={{
                                      input: "py-4 !rounded-lg w-full",
                                    }}
                                    error={
                                      errors?.[
                                        `module-${moduleIndex}-lesson-${lessonIndex}-lesson_details`
                                      ]?.message
                                    }
                                  />
                                )}
                              />

                              <Controller
                                name={`module-${moduleIndex}-lesson-${lessonIndex}-type_id`}
                                control={control}
                                rules={{
                                  required: {
                                    value: id && singleEducation ? false : true,
                                    message: `Please select your content type`,
                                  },
                                }}
                                render={({ field }) => (
                                  <Select
                                    {...field}
                                    mt="xl"
                                    label="Select Attachment Type"
                                    placeholder="Select the content type..."
                                    data={[
                                      { value: "1", label: "Video" },
                                      { value: "2", label: "File" },
                                      { value: "3", label: "URL" },
                                    ]}
                                    clearable
                                    searchable
                                    rightSectionWidth={30}
                                    className="w-full"
                                    classNames={{
                                      input: "!py-6 !rounded-lg !w-full",
                                    }}
                                    onChange={(value) => {
                                      field.onChange(value);
                                      clearErrors(`file`);
                                    }}
                                    disabled={!lessonID && id}
                                    error={
                                      errors?.[
                                        `module-${moduleIndex}-lesson-${lessonIndex}-type_id`
                                      ]?.message
                                    }
                                  />
                                )}
                              />

                              {watch(
                                `module-${moduleIndex}-lesson-${lessonIndex}-type_id`
                              ) && (
                                <div className="mt-4">
                                  <Controller
                                    name={`file`}
                                    control={control}
                                    {...register(
                                      `module-${moduleIndex}-lesson-${lessonIndex}-file`,
                                      {
                                        required: {
                                          value:
                                            id && singleEducation
                                              ? false
                                              : true,
                                          message:
                                            "Please insert your lesson attachment",
                                        },
                                        validate: {
                                          typeSelected: () => {
                                            const typeId = watch(
                                              `module-${moduleIndex}-lesson-${lessonIndex}-type_id`
                                            );
                                            return typeId
                                              ? true
                                              : "You must select the attachment type first, to upload.";
                                          },
                                        },
                                      }
                                    )}
                                    render={({ field }) => (
                                      <FileInput
                                        {...field}
                                        label="Upload File / Attachment"
                                        disabled={!lessonID && id}
                                        placeholder={
                                          "Upload your course attachment"
                                        }
                                        description={
                                          watch(
                                            `module-${moduleIndex}-lesson-${lessonIndex}-type_id`
                                          ) === "1"
                                            ? "You can upload videos"
                                            : watch(
                                                `module-${moduleIndex}-lesson-${lessonIndex}-type_id`
                                              ) === "2"
                                            ? "You can upload docs | attachment | ppt | pdf files"
                                            : "upload file attachments"
                                        }
                                        accept={
                                          watch(
                                            `module-${moduleIndex}-lesson-${lessonIndex}-type_id`
                                          ) === "1"
                                            ? "video/mp4, video/mov, video/avi, video/mkv, video/webm"
                                            : ".pdf, .doc, .docx, .odt, .ppt, .pptx, .odp, .txt,  .xlsx , .xls"
                                        }
                                        classNames={{
                                          input: "!rounded-lg w-full py-3",
                                        }}
                                        onChange={(file) => {
                                          field.onChange(file);
                                          handleFileChange(
                                            file,
                                            moduleIndex,
                                            lessonIndex
                                          );
                                        }}
                                        error={
                                          errors?.[
                                            `module-${moduleIndex}-lesson-${lessonIndex}-file`
                                          ]?.message
                                        }
                                      />
                                    )}
                                  />
                                  {uploadFileStatus[
                                    `module-${moduleIndex}-lesson-${lessonIndex}`
                                  ] && (
                                    <span className="text-sm text-hoverColor font-paragarphFont mt-2">
                                      {
                                        uploadFileStatus[
                                          `module-${moduleIndex}-lesson-${lessonIndex}`
                                        ]
                                      }
                                    </span>
                                  )}
                                </div>
                              )}
                            </div>
                          </div>
                        </Accordion.Panel>
                      ))}

                      {/* Add Lesson BTN */}
                      <Accordion.Panel>
                        <div
                          className={`my-4 px-12 text-main text-xl font-paragarphFont font-semibold flex justify-center items-center gap-1 w-full
              sm:text-base sm:w-auto before:h-0 before:m-2 before:border before:border-main before:flex-1 after:h-0 after:m-2 after:border after:border-main after:flex-1 
              ${
                id
                  ? "cursor-not-allowed opacity-50"
                  : "cursor-pointer hover:text-hoverColor"
              } transition ease-in-out duration-300`}
                          onClick={() => {
                            if (!id) {
                              handleAddLesson(moduleIndex);
                            }
                          }}
                        >
                          <IoIosAddCircleOutline /> Add a New Lesson
                        </div>
                      </Accordion.Panel>
                    </Accordion.Item>
                  ))}
                  <div
                    className={`mt-4 text-main text-xl font-paragarphFont font-semibold flex justify-center items-center gap-1 w-full
                      sm:text-base sm:w-auto before:h-0 before:m-2 before:border before:border-main before:flex-1 after:h-0 after:m-2 after:border after:border-main after:flex-1 
                      ${
                        id
                          ? "cursor-not-allowed opacity-50"
                          : "cursor-pointer hover:text-hoverColor"
                      } transition ease-in-out duration-300`}
                    onClick={() => {
                      if (!id) {
                        handleAddSection();
                      }
                    }}
                  >
                    <IoIosAddCircleOutline /> Add a New Section
                  </div>
                </Accordion>
              </div>

              {/* image */}
              <div
                className="flex flex-col justify-end items-start
               w-full lg:w-auto mb-12"
              >
                <Controller
                  name="image"
                  control={control}
                  render={({ field }) => (
                    <>
                      {!previewImage ? (
                        <FileInput
                          name="image"
                          {...field}
                          label="Course Image"
                          placeholder={<HiOutlinePhoto size={200} />}
                          accept="image/*"
                          disabled={chapterID || lessonID}
                          classNames={{ input: "!rounded-lg w-auto" }}
                          onChange={(file) => {
                            if (file && validateImage(file)) {
                              field.onChange(file);
                              if (file) {
                                const imageUrl = URL.createObjectURL(file);
                                setPreviewImage(imageUrl);
                                // handleImageUpload(file);
                                handleFileUpload(
                                  file,
                                  null,
                                  null,
                                  "image",
                                  "image"
                                );
                              } else {
                                setPreviewImage(null);
                                setImageUrl("");
                              }
                            }
                          }}
                          error={errors?.image?.message}
                        />
                      ) : (
                        <div className="mt-4 h-44">
                          <Image
                            src={previewImage}
                            alt="Image Preview"
                            className="w-full h-100 max-w-xs !rounded-lg"
                          />
                          <ActionIcon
                            size={32}
                            variant="default"
                            mt={"xl"}
                            onClick={() => {
                              setPreviewImage(null);
                              setUploadStatus("");
                              field.onChange(null);
                            }}
                          >
                            <BiTrash size={16} color="red" />
                          </ActionIcon>
                        </div>
                      )}
                    </>
                  )}
                />

                {uploadStatus && (
                  <span className="text-sm text-hoverColor font-paragarphFont mt-2">
                    {uploadStatus}
                  </span>
                )}
                {imageError && (
                  <Text color="red" size="sm" className="mt-2">
                    {imageError}
                  </Text>
                )}
              </div>
            </div>

            <div className="flex justify-end gap-2 mt-4">
              <button
                type="submit"
                disabled={!isValid || isUploading}
                className={`text-white bg-main text-sm px-4 py-2 rounded-full flex justify-center items-center gap-1 w-full
                sm:text-base sm:px-8 sm:w-auto
                ${
                  !isValid || isUploading ? "opacity-50 cursor-not-allowed" : ""
                }
                    `}
              >
                {id && singleEducation ? (
                  <BiSolidEdit />
                ) : (
                  <IoIosAddCircleOutline />
                )}
                {id && !chapterID && !lessonID
                  ? "udpate Course"
                  : chapterID
                  ? "Update Module"
                  : lessonID
                  ? "Update Lesson"
                  : "Add New Course"}
              </button>
            </div>
          </form>
        </div>
      </section>
    </>
  );
}
