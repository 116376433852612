import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const ZoomMeetings = createApi({
  reducerPath: "ZoomMeetingsAPI",
  baseQuery: fetchBaseQuery({
    baseUrl: `https://new-dashboard-staging.medicaspace.com`,
  }),
  tagTypes: ["upcoming-meetings"],
  endpoints: (builder) => ({
    ipAddress: builder.query({
      query: ({ email }) => ({
        url: `/api/upcoming-meetings`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        params: { email }, // Add the email as a query parameter
      }),
      providesTags: ["upcoming-meetings"],
    }),
  }),
});

export const { useIpAddressQuery } = ZoomMeetings;
