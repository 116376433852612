import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithReauth } from "../Auth/ConfigApi";
export const NotificationApi = createApi({
  reducerPath: "NotificationApi",
  baseQuery: baseQueryWithReauth,
  tagTypes: ["Notification"],

  endpoints: (builder) => ({
    getNotificationsList: builder.query({
      query: () => ({
        url: `/notifications`,
        headers: {
          Accept: "application/json",
        },
      }),
      providesTags: ["Notification"],
    }),
  }),
});

export const { useGetNotificationsListQuery } = NotificationApi;
